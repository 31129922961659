var sm =
{
availableIds: [],
bounds: {},
calledAjax: false,
controls: {},
current: {
	highestOccurance: { incidence: 0, percent: 0 },
	lowestOccurance: { rank: 9999999 },
	map: null,
	mapPublic: null,
	placeId: null,
	plot: 'incidence',
	view: null,
	viewPublic: null,
	year: null
},
data: {},
elements: {},
fullscreen: false,
historic: [1,2,3],
lang: {
	available: ['de','en','es','fr','it','nl','pt','sh'],
	de:
	{
		back: 'Zurück zur Welt',
		continents: 'Kontinente',
		districts: 'Bezirke',
		in: 'in',
		incidence: 'Vorfall',
		nations: 'Nationen',
		ratio: 'Häufigkeit',
		rank: 'Rang',
		regions: 'Regionen',
		search: 'Suche',
		selectPrompt: 'Wählen Sie eine Nation aus, um die Verteilung auf regionaler und subregionaler Ebene anzuzeigen',
		subregions: 'Unterregionen',
		world: 'Welt'
	},
	en:
	{
		back: 'Back to World View',
		continents: 'Continents',
		districts: 'Districts',
		in: 'in',
		incidence: 'Incidence',
		nations: 'Nations',
		ratio: 'Ratio',
		rank: 'Rank',
		regions: 'Regions',
		search: 'Search',
		selectPrompt: 'Select a nation to see the distribution at regional and subregional levels',
		subregions: 'Subregions',
		world: 'World'
	},
	es:
	{
		back: 'De Vuelta al Mundo',
		continents: 'Continentes',
		districts: 'Distritos',
		in: 'de cada',
		incidence: 'Incidencia',
		nations: 'Naciones',
		ratio: 'Frecuencia',
		rank: 'Calificación',
		regions: 'Regiones',
		search: 'Buscar',
		selectPrompt: 'Seleccione una nación para ver la distribución a nivel regional y subregional',
		subregions: 'Sous-régions',
		world: 'Mundo'
	},
	fr:
	{
		back: 'Retour au Monde',
		continents: 'Les continents',
		districts: 'Districts',
		in: 'sur',
		incidence: 'Incidence',
		nations: 'Des nations',
		ratio: 'Fréquence',
		rank: 'Rang',
		regions: 'Les Régions',
		search: 'Rechercher',
		selectPrompt: 'Sélectionnez une nation pour voir la distribution aux niveaux régional et sous-régional',
		subregions: 'Subregions',
		world: 'Monde'
	},
	it:
	{
		back: 'Ritorno Al Mondo',
		continents: 'Continenti',
		districts: 'Distretti',
		in: 'su',
		incidence: 'Incidenza',
		nations: 'Nazioni',
		ratio: 'Frequenza',
		rank: 'Rapporto',
		regions: 'Regioni',
		search: 'Ricerca',
		selectPrompt: 'Seleziona una nazione per vedere la distribuzione a livello regionale e subregionale',
		subregions: 'Sottoregioni',
		world: 'Mondo'
	},
	nl:
	{
		back: 'Terug Naar Wereld',
		continents: 'Continenten',
		districts: 'Districten',
		in: 'op',
		incidence: 'Verbreiding',
		nations: 'Landen',
		ratio: 'Frequentie',
		rank: 'Rang',
		regions: 'Regio\'s',
		search: 'Zoeken',
		selectPrompt: 'Selecteer een natie om de verdeling op regionaal en subregionaal niveau te bekijken',
		subregions: 'Subregio',
		world: 'Wereld'
	},
	pt:
	{
		back: 'De Volta ao Mundo',
		continents: 'Continentes',
		districts: 'Distritos',
		in: 'em',
		incidence: 'Incidência',
		nations: 'Nações',
		ratio: 'Frequência',
		rank: 'Posição',
		regions: 'Regiões',
		search: 'Procurar',
		selectPrompt: 'Selecione uma nação para ver a distribuição nos níveis regional e sub-regional',
		subregions: 'Sub-regiões',
		world: 'Mundo'
	},
	ru:
	{
		back: 'Назад к Карте Мира',
		continents: 'Континенты',
		districts: 'Районы',
		in: 'к',
		incidence: 'Количество',
		nations: 'Нации',
		ratio: 'Соотношение',
		rank: 'Ранг',
		regions: 'Регионы',
		search: 'Поиск',
		selectPrompt: 'Выберите страну, чтобы увидеть распределение на региональном и субрегиональном уровнях',
		subregions: 'Субрегионы',
		world: 'Мир'
	},
	sh:
	{
		back: 'Nazad u Svet',
		continents: 'Kontinenti',
		districts: 'Okruzi',
		in: 'od',
		incidence: 'Broj',
		nations: 'Nacije',
		ratio: 'Frekvencija',
		rank: 'Rang',
		regions: 'Regioni',
		search: 'Pretraga',
		selectPrompt: 'Izaberite naciju da biste videli zastupljenost na regionalnom i subregionalnom nivou',
		subregions: 'Podregoni',
		world: 'Svet'
	}
},
layer: false,
map: null,
nameType: false,
placeData: {
	10499: { name: 'Yorkshire' },
	5772: { name: 'Middlesex' },
	1109: { name: 'Cumberland' },
	9859: { name: 'Westmorland' },
	9306: { name: 'Sussex' },
	423914: { name: 'Kalbajar District' },
	423919: { name: 'Lachin District' }
},
possibleChildren: [],
surnameId: null,
tableHead: null,
updated: { geoJSON: false },

initialise: function( id )
{
	var parts = location.pathname.split( '/' );
	if( typeof parts[1] !== 'undefined' && !parts[1] ) return false;
	sm.nameType = parts[parts.length - 2].substring( 0, parts[parts.length - 2].length - 1 );
	sm.map = L.map( id ).setView( [31,14], 2 );
	sm.map.attributionControl.addAttribution( 'Population data &copy; <a href="https://forebears.io/">Forebears</a>' );
	sm.surnameId = $( '*[data-sid]' ).attr( 'data-sid' );
	sm.initialiseFullscreen();
	sm.initialiseInfoBox();
	sm.bounds[454481] = sm.map.getBounds();
},

postProcess: function()
{
	sm.setStyles();
	sm.setAvailableMaps();
	sm.setAvailableViews();
	sm.setAvailableYears();
	sm.setPlaceTables();
},

setPlaceTables: function()
{
	if( sm.calledAjax == true )
	{
		var navEl = $( '.distribution-holder .table-navigation' )
		navEl.html( '' );
		var nav = '';
		$.each( sm.data[sm.current.placeId][sm.current.view], function( year )
		{
			var cls = ( sm.current.year == year ) ? ' class="active"' : '';
			nav += '<li><a data-toggle="tab" href="#place-tab-' + year + '"' + cls +'>' + year + '</a></li>';
		});
		navEl.html( nav );
		var tblEl = $( '.distribution-holder data-tabset' );
		if( tblEl.find( 'th' ).length == 5 )
			tblEl.find( 'th' ).slice( 1, 2 ).remove();
		sm.tableHead = ( sm.tableHead == null ) ? $( tblEl.find( 'thead' )[0] ).html() : sm.tableHead;
		tblEl.html( '' );
		var tabs = '';
		$.each( sm.data[sm.current.placeId][sm.current.view], function( year, rows )
		{
			var cls = ( sm.current.year == year ) ? ' show active' : '';
			tabs += '<data-tab role="tabpanel" id="place-tab-' + year + '" data-heading="' + year + '" class="tab-pane fade' + cls + '" aria-labelledby="place-tab-' + year + '"><div class="table-responsive table-collapsable"><table class="table nation-table"><thead>' + sm.tableHead + '</thead>';
			keys = Object.keys( rows ).sort( function( a, b ) { return rows[b].incidence - rows[a].incidence } );
			$.each( keys, function( index, key )
			{
				var ico = ( typeof sm.placeData[key] != 'undefined' && typeof sm.placeData[key].iso != 'undefined' ) ? '<svg class="flag-icon"><use href="/assets/img.svg#' + sm.placeData[key].iso + '"></use></svg>' : '';
				var name = ( typeof sm.placeData[key] != 'undefined' ) ? sm.placeData[key].name : '-';
				if( ( typeof sm.placeData[key] == 'undefined' ) )
					console.log( key + ' - missing placeId' );
				tabs += '<tr><td>' + ico + name + '</td><td>' + sm.groupedThousands( rows[key].incidence ) + '</td><td><span data-toggle="tooltip" title="' + rows[key].percent + '">1:' + sm.groupedThousands( rows[key].ratio ) + '</span></td><td>' + sm.groupedThousands( rows[key].rank ) + '</td></tr>';
			});
			tabs += '</table></div></data-tab>';
		});
		tblEl.html( tabs );
	}
},

setAvailableMaps: function()
{
	this.setinnerHTML( 'toggle-map', sm.current.mapPublic );
	var options = $( '#options-map' );
	options.empty();
	
	if( sm.current.view == 'Continents' )
		$( '#toggle-map' ).addClass( 'disabled' );
	else if( sm.current.view == 'Regions' || sm.current.view == 'Subregions' )
	{
		$( '#toggle-map' ).removeClass( 'disabled' );
		options.append( '<a class="dropdown-item" onclick="sm.setMap( 454481, \'World\' )">' + sm.lang[fb.getSiteLanguage()].world + '</a>' );
	}
	else
	{
		var url = 'a?c=Name&m=getAvailable&i=' + sm.surnameId + '&t=' + sm.nameType;
		$.ajax(
		{
			type: 'POST',
			url: url,
			data: { ps: sm.possibleChildren.join( ',' ) },
			success: function( data )
			{
				sm.availableIds = jQuery.parseJSON( data );
				if( sm.availableIds.length > 10 )
					options.append( '<a class="dropdown-filter"><input type="text" onkeyup="fb.searchDropdown( this, \'options-map-holder\' )" placeholder="' + sm.lang[fb.getSiteLanguage()].search + '" /></a>' );
				$( '#toggle-map' ).removeClass( 'disabled' );
				$.each( sm.geoJSON.features, function( index, feature )
				{
					if( typeof feature.id !== 'undefined' && $.inArray( feature.id, sm.availableIds ) != -1 )
					{
						var iso = ( typeof feature.properties.iso !== 'undefined'  ) ? '<svg class="flag-icon"><use href="/assets/img.svg#' + feature.properties.iso + '"></use></svg>' : '';
						options.append( '<a class="dropdown-item" onclick="sm.setMap( ' + feature.id + ', \'' + feature.properties.name + '\' )">' + iso + '' + feature.properties.name + '</a>' );
					}
				});
			}
		});
	}
},

setAvailableViews: function()
{
	this.setinnerHTML( 'toggle-view', sm.current.viewPublic );
	var options = $( '#options-view' );
	options.empty();
	var views = [];
	if( sm.current.map == 'World' )
	{
		if( sm.current.year == 2014 ) views.push( { i: 'Continents', p: sm.lang[fb.getSiteLanguage()].continents } );
		views.push( { i: 'Nations', p: sm.lang[fb.getSiteLanguage()].nations } );
	}
	else if( $.inArray( sm.current.placeId, [17725,17676,17697,16574,17739,17745,285765,17108,17614,17663,13963,16462,13960,17661,17719,17093,13967,17633,18001,17609,13969,13959,17102,13958,17699,13947,17679,17689,17690,17657,13942,13931,17096,13935,16577,17624,13934,17622,17112,17714,13932,17738,17104,17092,16465,18000,13966,17110,16463,13939,13943,13968,16575,17673,16464,17669,17103,17107,13933,17095,17631,17638,1,17655,13945,13888,13948,17667,17667,17677,13914,17106,17704,13965] ) != -1 )
	{
		views.push( { i: 'Regions', p: sm.lang[fb.getSiteLanguage()].regions } );
		views.push( { i: 'Subregions', p: sm.lang[fb.getSiteLanguage()].subregions } );
		if( $.inArray( sm.current.placeId, [17697,16574,13933,13966,17609,16462] ) != -1 )
			views.push( { i: 'Districts', p: sm.lang[fb.getSiteLanguage()].districts } );
	}
	$.each( views, function( index, view )
	{
		options.append( '<a class="dropdown-item" onclick="sm.setView( \'' + view.i + '\', \'' + view.p + '\' )">' + view.p + '</a>' );
	});
	if( views.length < 2 )
		$( '#toggle-view' ).addClass( 'disabled' );
	else
		$( '#toggle-view' ).removeClass( 'disabled' );
},

setAvailableYears: function()
{
	this.setinnerHTML( 'toggle-year', sm.current.year );
	var options = $( '#options-year' );
	options.empty();
	var i = 0;
	$.each( sm.data[sm.current.placeId][sm.current.view], function( year )
	{
		options.append( '<a class="dropdown-item" onclick="sm.setYear( ' + year + ' )">' + year + '</a>' );
		i++;
	});
	if( i < 2 )
		$( '#toggle-year' ).addClass( 'disabled' );
	else
		$( '#toggle-year' ).removeClass( 'disabled' );
},

setMap: function( placeId, name )
{
	sm.current.map = name;
	sm.current.placeId = placeId;
	sm.current.view = ( sm.current.placeId != 454481 ) ? 'Regions' : 'Nations';
	sm.current.viewPublic = sm.lang[fb.getSiteLanguage()][sm.current.view.toLowerCase()];
	sm.current.highestOccurance = { incidence: 0, percent: 0 };
	sm.current.lowestOccurance = { rank: 9999999 };
	sm.getNameGeo();
	sm.getGeoJSON( sm.current.view );
	if( typeof sm.elements.back != 'undefined' )
	{
		sm.controls.backNavigation.remove();
		delete sm.elements.back;
	}
	sm.map.fitBounds( sm.bounds[placeId] );
},

setView: function( view, public )
{
	sm.current.view = view;
	sm.current.viewPublic = public;
	sm.current.highestOccurance = { incidence: 0, percent: 0 };
	sm.current.lowestOccurance = { rank: 9999999 };
	sm.getNameGeo();
	sm.getGeoJSON( view );
},

setYear: function( year )
{
	sm.current.year = year;
	sm.getNameGeo();
	sm.getGeoJSON( sm.current.view );
},

setPlot: function( type, id, text )
{
	sm.current.plot = type;
	sm.applyShading();
	sm.setinnerHTML( id, text );
},

getNameGeo: function()
{
	if( typeof sm.data[sm.current.placeId] !== 'undefined' && typeof sm.data[sm.current.placeId][sm.current.view] !== 'undefined' ) return false;
	sm.calledAjax = true;
	if( sm.current.view == 'Continents' )
		var url = 'a?c=Name&m=getGeo&i=' + sm.surnameId + '&t=continent';
	else if( sm.current.view == 'Subregions' )
		var url = 'a?c=Name&m=getGeoSub&i=' + sm.surnameId + '&p=' + sm.current.placeId + '&t=subregion';
	else if( sm.current.view == 'Districts' )
		var url = 'a?c=Name&m=getGeoSub&i=' + sm.surnameId + '&p=' + sm.current.placeId + '&t=district';
	else
		var url = 'a?c=Name&m=getGeo&i=' + sm.surnameId + '&p=' + sm.current.placeId;
	url += '&nt=' + sm.nameType;
	$.ajax(
	{
		type: 'GET',
		url: url,
		dataType: 'json',
		success: function( data )
		{
			sm.nest( sm.data, [sm.current.placeId, sm.current.view], data );
			if( typeof sm.data[sm.current.placeId] !== 'undefined' && sm.updated.geoJSON == true )
				sm.updateGeoJSON();
		}
	});
},

getGeoJSON: function( view )
{
	sm.updated.geoJSON = false;
	var appendage = ( sm.current.year != 2014 && $.inArray( sm.current.placeId, sm.historic ) !== -1 ) ? '.Historic' : '';
	var view = ( view == 'Nations' ) ? view + '.' + fb.getSiteLanguage() : view;
	var url = '/assets/geoJSON/' + sm.current.placeId + '.' + view + appendage + '.json';
	
	$.ajax(
	{
		xhr: function ()
		{
			var xhr = new window.XMLHttpRequest();
			sm.initialiseLoadBox();
			xhr.addEventListener( 'load', function()
			{
				setTimeout( function()
				{
					sm.controls.loadBox.remove();
					if( sm.current.placeId != 454481 )
						sm.initialiseBackNavigation();
				}, 500 );
				return false;
			});
			xhr.addEventListener( 'progress', function( e )
			{
				if( e.lengthComputable )
					var percent = e.loaded / e.total * 100;
				else
				{
					var total = e.target.getResponseHeader( 'content-length' );
					var encoding = e.target.getResponseHeader( 'content-encoding' );
				}
				if( total && encoding && ( encoding.indexOf( 'gzip' ) > -1 ) )
				{
					total = total * 4.5;
					var percent = Math.min( 100, e.loaded / total * 100 );
				}
				if( percent <= 100 )
					sm.controls.loadBox.update( parseInt( percent ) );
			}, false );
			return xhr;
		},
		type: 'GET',
		url: url,
		async: true,
		success: function( data )
		{
			sm.geoJSON = data;
			sm.updated.geoJSON = true;
			if( typeof sm.data[sm.current.placeId] !== 'undefined' && sm.updated.geoJSON == true )
				sm.updateGeoJSON();
		}
	});
},

initialiseBackNavigation: function()
{
	if( typeof sm.elements.back != 'undefined' ) sm.elements.back.remove();
	sm.controls.backNavigation = L.control( { position: 'bottomleft' } );
	sm.controls.backNavigation.onAdd = function()
	{
		sm.elements.back = L.DomUtil.create( 'div', 'map-box' );
		sm.elements.back.innerHTML = '<button class="btn btn-map btn-label" onclick="sm.controls.backNavigation.onClick()">' + sm.lang[fb.getSiteLanguage()].back + '</button>';
		return sm.elements.back;
	};
	sm.controls.backNavigation.onClick = function( percent )
	{
		sm.setMap( 454481, sm.lang[fb.getSiteLanguage()].world );
		sm.controls.backNavigation.remove();
		delete sm.elements.back;
	};
	sm.controls.backNavigation.addTo( sm.map );
},

initialiseLoadBox: function()
{
	if( typeof sm.elements.load != 'undefined' ) sm.elements.load.remove();
	sm.controls.loadBox = L.control( { position: 'bottomleft' } );
	sm.controls.loadBox.onAdd = function()
	{
		sm.elements.load = L.DomUtil.create( 'div', 'map-box' );
		return sm.elements.load;
	};
	sm.controls.loadBox.update = function( percent )
	{
		sm.elements.load.innerHTML = '<h5>' + percent + '%</h5>';
	};
	sm.controls.loadBox.addTo( sm.map );
},

updateGeoJSON: function()
{
	sm.possibleChildren = [];
	sm.updatedGeoJSON = true;
	$.each( sm.geoJSON.features, function( index, feature )
	{
		if( typeof sm.data[sm.current.placeId][sm.current.view] != 'undefined' && typeof sm.data[sm.current.placeId][sm.current.view][sm.current.year][feature.id] !== 'undefined' )
		{
			sm.possibleChildren.push( feature.id );
			sm.placeData[feature.id] = { name: feature.properties.name };
			if( typeof feature.properties.iso != 'undefined' )
				sm.placeData[feature.id].iso = feature.properties.iso;
			feature.properties.incidence = sm.data[sm.current.placeId][sm.current.view][sm.current.year][feature.id].incidence;
			feature.properties.percent = sm.data[sm.current.placeId][sm.current.view][sm.current.year][feature.id].percent;
			feature.properties.ratio = sm.data[sm.current.placeId][sm.current.view][sm.current.year][feature.id].ratio;
			feature.properties.rank = sm.data[sm.current.placeId][sm.current.view][sm.current.year][feature.id].rank;
			if( sm.data[sm.current.placeId][sm.current.view][sm.current.year][feature.id].incidence > sm.current.highestOccurance.incidence )
				sm.current.highestOccurance.incidence = sm.data[sm.current.placeId][sm.current.view][sm.current.year][feature.id].incidence;
			if( sm.data[sm.current.placeId][sm.current.view][sm.current.year][feature.id].percent > sm.current.highestOccurance.percent )
				sm.current.highestOccurance.percent = sm.data[sm.current.placeId][sm.current.view][sm.current.year][feature.id].percent;
			if( sm.data[sm.current.placeId][sm.current.view][sm.current.year][feature.id].rank < sm.current.lowestOccurance.rank )
				sm.current.lowestOccurance.rank = sm.data[sm.current.placeId][sm.current.view][sm.current.year][feature.id].rank;
		}
		else
		{
			feature.properties.incidence = 0;
			feature.properties.percent = 0;
			feature.properties.rank = 0;
		}
	});
	
	sm.applyShading();
},

initialiseDataFromTables: function( selector )
{
	sm.current.placeId = 454481;
	sm.current.map = 'World';
	sm.current.mapPublic = sm.lang[fb.getSiteLanguage()].world;
	sm.current.view = 'Nations';
	sm.current.viewPublic = sm.lang[fb.getSiteLanguage()].nations;
	var tables = $( selector );
	var lastYear = null;
	$.each( tables, function( index, table )
	{
		var data = {};
		var trs = $( table ).find( 'tr' );
		var year = $( table ).attr( 'data-year' );
		if( year > lastYear )
			lastYear = year;
		
		$.each( trs, function( index2, tr )
		{
			var tds = $( tr ).find( 'td' );
			if( tds.length != 4 && tds.length != 5 ) return true;
			var mod = ( tds.length == 4 ) ? 0 : 1;
			var placeId = parseInt( $( tr ).attr( 'data-pid' ) );
			var row = {
				incidence: parseInt( tds[1 + mod].innerHTML.split( ',' ).join( '' ) ),
				ratio: $( tds[2 + mod] ).find( 'span' )[0].innerHTML,
				percent: $( $( tds[2 + mod] ).find( 'span' )[0] ).attr( 'data-original-title' ).replace( '%', '' ),
				rank: parseFloat( tds[3 + mod].innerHTML.split( ',' ).join( '' ) )
			};
			data[placeId] = row;
		});
		sm.nest( sm.data, [sm.current.placeId, sm.current.view, year], data );
	});
	
	sm.current.year = lastYear;
	sm.getGeoJSON( 'Nations' );
},

nest: function( obj, keys, v )
{
	if( keys.length === 1 )
		obj[keys[0]] = v;
	else
	{
		var key = keys.shift();
		obj[key] = sm.nest( typeof obj[key] === 'undefined' ? {} : obj[key], keys, v );
	}
	return obj;
},

applyShading: function()
{
	var stats = [];
	var lastDif = 2;
	var drop = 2;
	if( sm.current.plot == 'incidence' || sm.current.plot == 'percent' )
		var lastTo = sm.current.highestOccurance[sm.current.plot];
	var ranges = [];
	$.each( sm.geoJSON.features, function( index, feature )
	{
		if( typeof feature.properties[sm.current.plot] !== 'undefined' && feature.properties[sm.current.plot] != 0 )
		{
			if( sm.current.plot == 'incidence' )
				stats.push( { value: feature.properties[sm.current.plot], underLast: feature.properties[sm.current.plot] / sm.current.highestOccurance.incidence } );
			else if( sm.current.plot == 'percent' )
				stats.push( { value: feature.properties[sm.current.plot], underLast: feature.properties[sm.current.plot] / sm.current.highestOccurance.percent } );
			else
				stats.push( { value: feature.properties[sm.current.plot], underLast: sm.current.lowestOccurance.rank / feature.properties[sm.current.plot] } );
		}
	});
	if( sm.current.plot == 'rank' )
		stats.sort( function( a, b ) { return( a.value > b.value ) ? 1 : ( ( b.value > a.value ) ? -1 : 0 ) ;} );
	else
		stats.sort( function( a, b ) { return( a.value < b.value ) ? 1 : ( ( b.value < a.value ) ? -1 : 0 ) ;} );
	if( sm.current.plot == 'incidence' )
	{
		$.each( stats, function( index, stat )
		{
			var dif = stat.underLast * drop;
			if( lastDif && ( stat.underLast <= lastDif ) )
			{
				lastDif = dif;
				if( stat.underLast < 0.02 )
					drop -= 0.05;
				if( stat.value == sm.current.highestOccurance.incidence )
				{
					ranges.push( { from: stat.value, to: stat.value } );
					lastTo = stat.value - 1;
				}
				else if( lastTo > ( stat.value + 1 ) )
				{
					ranges.push( { from: stat.value + 1, to: lastTo } );
					lastTo = stat.value;
				}
			}
			else
				lastDif = dif;
		});
		ranges.push( { from: 1, to: ( lastTo > 1 ) ? lastTo: 1 } );
		ranges.reverse();
	}
	else if( sm.current.plot == 'percent' )
	{
		$.each( stats, function( index, stat )
		{
			var dif = stat.underLast * drop;
			if( lastDif && ( stat.underLast <= lastDif ) )
			{
				lastDif = dif;
				if( stat.underLast < 0.02 )
					drop -= 0.02;
				if( stat.value == sm.current.highestOccurance.percent )
				{
					ranges.push( { from: stat.value, to: stat.value } );
					lastTo = stat.value - 1;
				}
				else
				{
					ranges.push( { from: stat.value, to: 0 } );
				}
			}
			else
				lastDif = dif;
		});
		var lastFrom = false;
		$.each( ranges, function( index, range )
		{
			if( lastFrom == false )
				range.to = range.from;
			else
				range.to = lastFrom;
			lastFrom = range.from;
		});
		ranges.reverse();
	}
	else
	{
		$.each( stats, function( index, stat )
		{
			var dif = stat.underLast * drop;
			if( lastDif && ( stat.underLast <= lastDif ) )
			{
				lastDif = dif;
				if( stat.underLast < 0.02 )
					drop -= 0.01;
				if( stat.value == sm.current.lowestOccurance.rank )
					ranges.push( { from: stat.value, to: stat.value } );
				else
					ranges.push( { from: stat.value, to: 0 } );
			}
			else
				lastDif = dif;
		});
		ranges.reverse();
		var lastFrom = false;
		$.each( ranges, function( index, range )
		{
			if( lastFrom == false )
				range.to = range.from;
			else
				range.to = lastFrom;
			lastFrom = range.from - 1;
		});
	}
	sm.plotRanges = ranges;
	sm.numberOfRanges = ranges.length;
	sm.postProcess();
},

groupedThousands: function( str )
{
	return str.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' );
},

parseRatio: function( str )
{
	if( typeof str == 'undefined' ) return '';
	str = str.toString();
	if( str.indexOf( ':' ) != -1 )
	{
		var parts = str.split( ':' );
		var ratio = parts[1].replace( /,/g, '' ).toString();
	}
	else
		var ratio = str;
	var len = ratio.length;
	var short = '';
	if( len <= 3 ) short = ratio;
	else if( len == 4 ) short = ratio.charAt( 0 ).concat( ( ratio.charAt( 1 ) != 0 ) ? '.' + ratio.charAt( 1 ) + 'K' : 'K' );
	else if( len == 5 ) short = ratio.charAt( 0 ).concat( ratio.charAt( 1 ) ).concat( 'K' );
	else if( len == 6 ) short = ratio.charAt( 0 ).concat( ratio.charAt( 1 ) ).concat( ratio.charAt( 2 ) ).concat( 'K' );
	else if( len == 7 ) short = ratio.charAt( 0 ).concat( ( ratio.charAt( 1 ) != 0 ) ? '.' + ratio.charAt( 1 ) + 'M' : 'M' );
	else if( len == 8 ) short = ratio.charAt( 0 ).concat( ratio.charAt( 1 ) ).concat( 'M' );
	else if( len == 9 ) short = ratio.charAt( 0 ).concat( ratio.charAt( 1 ) ).concat( ratio.charAt( 2 ) ).concat( 'M' );
	else if( len >= 10 ) short = ratio.charAt( 0 ).concat( ( ratio.charAt( 1 ) != 0 ) ? '.' + ratio.charAt( 1 ) + 'B' : 'B' );
	return '1 ' + sm.lang[fb.getSiteLanguage()].in + ' ' + short;
},

initialiseInfoBox: function()
{
	sm.info = L.control();
	sm.info.onAdd = function()
	{
		sm.elements.info = L.DomUtil.create( 'div', 'map-box' );
		this.update();
		sm.elements.info.hovered = false;
		$( sm.elements.info ).hover( function() { sm.elements.info.hovered = true; }, function() { sm.elements.info.hovered = false; });
		return sm.elements.info;
	};
	sm.info.update = function( properties )
	{
		setTimeout( function()
		{
			if( sm.elements.info.hovered == true )
				return false;
			if( properties && properties.name )
			{
				sm.elements.info.innerHTML = '<h5>' + properties.name + '</h5>';
				$( sm.elements.info ).show();
			}
			if( properties && properties.incidence > 0 )
			{
				sm.elements.info.innerHTML += '<h6><b>' + sm.lang[fb.getSiteLanguage()].incidence + '</b>: ' + sm.groupedThousands( properties.incidence ) + '</h6>';
				sm.elements.info.innerHTML += '<h6><b>' + sm.lang[fb.getSiteLanguage()].ratio + '</b>: ' + sm.parseRatio( properties.ratio ) + '</h6>';
				sm.elements.info.innerHTML += '<h6><b>' + sm.lang[fb.getSiteLanguage()].rank + '</b>: ' + sm.groupedThousands( properties.rank ) + '</h6>';
			}
			if( typeof properties == 'undefined' )
				$( sm.elements.info ).hide();
			
		}, 10 );
	};
	sm.info.addTo( sm.map );
},

approximateColor1ToColor2ByPercent: function( color1, color2, percent )
{
	if( typeof percent == 'undefined' ) return '#ccc';
	var red1 = parseInt( color1[1] + color1[2], 16 );
	var green1 = parseInt( color1[3] + color1[4], 16 );
	var blue1 = parseInt( color1[5] + color1[6], 16 );
	
	var red2 = parseInt( color2[1] + color2[2], 16 );
	var green2 = parseInt( color2[3] + color2[4], 16 );
	var blue2 = parseInt( color2[5] + color2[6], 16 );
	
	var red = Math.round( sm.mix(red1, red2, percent ) );
	var green = Math.round( sm.mix(green1, green2, percent ) );
	var blue = Math.round( sm.mix(blue1, blue2, percent ) );
	
	return sm.generateHex( red, green, blue );
},

generateHex: function (r, g, b)
{
  r = r.toString( 16 );
  g = g.toString( 16 );
  b = b.toString( 16 );

  // to address problem mentioned by Alexis Wilke:
  while ( r.length < 2 ) { r = "0" + r; }
  while ( g.length < 2 ) { g = "0" + g; }
  while ( b.length < 2 ) { b = "0" + b; }

  return "#" + r + g + b;
},

mix: function ( start, end, percent )
{
    return start + ( ( percent ) * ( end - start ) );
},

style: function( feature )
{
	var v = {};
	$.each( sm.plotRanges, function( index, range )
	{
		if( feature.properties[sm.current.plot] >= range.from && feature.properties[sm.current.plot] <= range.to )
		{
			v.v = ( index + 1 ) / sm.numberOfRanges;
			return true;
		}
	});
	return {
		weight: 1,
		color: ( typeof feature.properties.dispute !== 'undefined' ) ? '#888' : '#bbb',
		dashArray: ( typeof feature.properties.dispute !== 'undefined' ) ? '4,4' : ' ',
		fillOpacity: 1,
		// cdd2ec , 1b2452 <- blue 
		// d4cdec, 281b52 <- purple
		// C7D4C6, 1B5244 <- green
		// fde093, 800026 <- orange, red
		// '#ffe992', '#b41d21' <- other yellow-red
		fillColor: sm.approximateColor1ToColor2ByPercent( '#ffe992', '#b41d21', v.v ),
		id: feature.id
	};
},

highlightFeature: function( e )
{
	var layer = e.target;
	layer.setStyle(
	{
		weight: 1,
		dashArray: '',
		fillColor: '#281b52'
	});
	
	sm.info.update( layer.feature.properties );
},

resetHighlight: function( e )
{
	sm.layer.resetStyle( e.target );
	sm.info.update();
},

setMapByPath: function( e )
{
	if( typeof e.sourceTarget.feature.id !== 'undefined' && $.inArray( e.sourceTarget.feature.id, sm.availableIds ) != -1 )
	{
		sm.setMap( e.sourceTarget.feature.id, e.sourceTarget.feature.properties.name );
	}
},

onEachFeature: function( feature, layer )
{
	if( typeof layer.feature.id !== 'undefined' )
		sm.bounds[layer.feature.id] = layer._bounds;
	layer.on(
	{
		mouseover: sm.highlightFeature,
		mouseout: sm.resetHighlight,
		click: sm.setMapByPath
	});
},

setStyles: function()
{
	if( sm.layer !== false )
		sm.map.removeLayer( sm.layer );
	sm.layer = L.geoJson( sm.geoJSON,
	{
		style: sm.style,
		onEachFeature: sm.onEachFeature
	}).addTo( sm.map );
},

setinnerHTML: function( id, text )
{
	$( '#' + id ).html( text );
},

toggleFullscreen: function()
{
	if( sm.fullscreen == false )
	{
		var el = document.getElementById( 'map-area' );
		var func = el.requestFullscreen || el.msRequestFullscreen || el.webkitRequestFullScreen || el.mozRequestFullScreen
		if( func ) func.call( el );
	}
	else
	{
		var func = document.exitFullscreen || document.msExitFullscreen || document.mozCancelFullScreen || document.webkitExitFullscreen || document.webkitCancelFullScreen;
		if( func ) func.call( document );
	}
},

initialiseFullscreen: function()
{
	sm.elements.mapArea = $( '#map-area' );
	sm.elements.map = $( '#map' );
	$( document ).on( 'mozfullscreenchange fullscreenchange webkitfullscreenchange msfullscreenchange MSFullscreenChange', function()
	{
		if( sm.fullscreen == false )
		{
			sm.elements.mapArea.css( 'height', $( window ).height() + 'px' );
			sm.elements.mapArea.css( 'width', '100%' );
			sm.elements.map.css( 'height', '93.7%' );
			sm.fullscreen = true;
		}
		else
		{
			sm.elements.mapArea.css( 'height', '100%' );
			sm.elements.mapArea.css( 'width', '100%' );
			sm.elements.map.css( 'height', '600px' );
			sm.fullscreen = false;
		}
	});
},

initialiseMessage: function()
{
	var mapLoads = ( fb.storageAvailable() && localStorage.getItem( 'map-loads' ) ) ? parseInt( localStorage.getItem( 'map-loads' ) ) : 0;
	if( fb.storageAvailable() )
		localStorage.setItem( 'map-loads', mapLoads + 1 );
	if( mapLoads <= 3 )
		$( '.map-filters' ).after( '<div class="alert alert-warning mt-3">' + sm.lang[fb.getSiteLanguage()].selectPrompt + '</div>' );
}
}

var nt =
{
initialiseAlternateLanguages: function( id, langId, type )
{
	var siteLang = fb.getSiteLanguage();
	var langPath = ( siteLang != 'en' ) ? '/x/' + siteLang : '/x';
	$( '#translated-descriptions' ).append( '<iframe id="translated" src="//' + window.location.host + langPath + '/translate-name?nameId=' + id + '&lang=' + langId + '&type=' + type + '" seamless class="iframe">' );
	$( '.translated-descriptions' ).remove();
	$( '#translated' ).on( 'load', function () { $( this ).height( $( this ).contents().height() + 130 ); });
	$( 'html, body' ).animate( { scrollTop: $( '#translated-descriptions' ).offset().top + - 150 }, 50 );
}
}

var ns =
{
get: function( nameId, statId, placeId, nation, ico, type )
{
	if( $( '.btn-sidebar-toggle' ).is( ':visible' ) )
		$( '.sidebar .sidebar-inner' ).css( { display: 'none', opacity: 0, translateY: -10 } );
	var url = 'a?c=Name&m=getDemographics';
	$.ajax(
	{
		type: 'POST',
		url: url,
		data: { nameId: nameId, statId: statId, placeId: placeId, nation: nation, type: type },
		dataType: 'json',
		success: function( data )
		{
			$( '#demographic-content' ).html( data.html );
			$( '#demo-type-' + statId + ' span' ).html( nation );
			$( '#demo-type-' + statId + ' use' ).attr( 'href', '/assets/img.svg#' + ico );
		}
	});
}
}

if( $( '#map' ).length > 0 )
{
	sm.initialise( 'map' );
	sm.initialiseDataFromTables( '.nation-table' );
	sm.initialiseMessage();
}